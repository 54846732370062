import React, { Component } from 'react'

import WhiteLogo from '../components/whiteLogo'
import Layout from '../components/layout'
import CnHome from '../components/cn/home'
import EnHome from '../components/en/home'

import cnFlag from '../images/zh-hans.png'
import enFlag from '../images/en.png'

class IndexPage extends Component {
  state = {
    cn: null,
  }

  enToggle = () => {
    this.setState({
      cn: false,
    })
    localStorage.setItem('cn', false)
  }
  cnToggle = () => {
    this.setState({
      cn: true,
    })
    localStorage.setItem('cn', true)
  }

  hydrateStateWithLocalStorage() {
    for (let key in this.state) {
      if (localStorage.hasOwnProperty(key)) {
        let value = localStorage.getItem(key)
        try {
          value = JSON.parse(value)
          this.setState({ [key]: value })
        } catch (e) {
          this.setState({ [key]: value })
        }
      }
    }
  }
  componentDidMount() {
    if (localStorage.getItem('cn') === null) {
      this.setState({ cn: true })
    }
    this.hydrateStateWithLocalStorage()
  }

  render() {
    const CnMenu = () => (
      <>
        <h1>设定最高的患者安全标准</h1>
        <div className="home">
          <CnHome />
        </div>
      </>
    )
    const EnMenu = () => (
      <>
        <h1>SETTING THE HIGHEST STANDARDS FOR PATIENT SAFETY</h1>
        <div className="home">
          <EnHome />
        </div>
      </>
    )
    const LangSelect = () => (
      <div className="languages">
        <div className="cn-toggle" onClick={this.cnToggle}>
          <img alt="cn-flag" src={cnFlag} />
          <span>简体中文</span>
        </div>
        <div className="en-toggle" onClick={this.enToggle}>
          <img alt="en-flag" src={enFlag} />
          <span>English</span>
        </div>
      </div>
    )
    return (
      <Layout>
        <div className="app-main home-page">
          <WhiteLogo />
          {this.state.cn || this.state.cn === null ? <CnMenu /> : <EnMenu />}
        </div>
        <LangSelect />
      </Layout>
    )
  }
}

export default IndexPage
