import React from 'react'
import {Link} from 'gatsby'
import { StaticQuery, graphql} from 'gatsby'

const WhiteLogo = () => (
  <StaticQuery
    query={graphql`
      query LogoQuery {
        wordpressWpMedia(title: {eq: "armstrong-logo"}) {
          source_url
        }
      }
    `}
    render={data => (
      <Link to="/">
        <img className="logo" alt="armstrong logo" src={data.wordpressWpMedia.source_url} />
      </Link>
    )}
  />
)

export default WhiteLogo