import React from 'react'
import { Link } from 'gatsby'

import Divider from '../../images/divider.png'

const PageList = () => (
  <ul className="home-nav">
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/cn/the-threat-of-legionella">军团菌的威胁</Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/cn/reducing-risks-for-legionella-and-scalding">
        减少军团菌和烫伤风险
      </Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/cn/world-class-hot-water-system-solutions-for-healthcare">
        为医疗行业量身定制的热水系统解决方案
      </Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/cn/online-courses-for-the-healthcare-industry">
        医疗服务行业在线课程
      </Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/cn/armstrongs-global-expertise-in-healthcare">
        阿姆斯壮在全球医疗方面的专业知识
      </Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/cn/ready-to-know-more">想要更了更多？</Link>
      <img alt="divider" src={Divider} />
    </li>
  </ul>
)
export default PageList
