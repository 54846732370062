import React from 'react'
import { Link } from 'gatsby'

import Divider from '../../images/divider.png'

const PageList = () => (
  <ul className="home-nav">
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/en/the-threat-of-legionella">Legionella</Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/en/reducing-risks-for-legionella-and-scalding">
        Reducing Risks
      </Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/en/world-class-hot-water-system-solutions-for-healthcare">
        Hot Water Solutions
      </Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/en/online-courses-for-the-healthcare-industry">
        Online Courses
      </Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/en/armstrongs-global-expertise-in-healthcare">
        Armstrong International
      </Link>
    </li>
    <li>
      <img alt="divider" src={Divider} />
      <Link to="/en/ready-to-know-more">Learn More</Link>
      <img alt="divider" src={Divider} />
    </li>
  </ul>
)
export default PageList
